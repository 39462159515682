import { HiOutlineHome } from "react-icons/hi2";
import { RxDashboard } from "react-icons/rx";
import { CgNotes } from "react-icons/cg";

import routes from "@src/routes/routes";

export const SIDEBAR_MENU_LIST = [
  { label: "Home", link: routes.Home, Icon: HiOutlineHome },
  { label: "All Tests", link: routes.TestList, Icon: RxDashboard },
  { label: "Create Test", link: routes.TestCreate, Icon: CgNotes },
  { label: "All Questions", link: routes.QuestionList, Icon: RxDashboard },
  { label: "Create Question", link: routes.QuestionCreate, Icon: RxDashboard },
  { label: "Create Question", link: routes.QuestionCreate, Icon: RxDashboard },
  { label: "Create Question", link: routes.QuestionCreate, Icon: RxDashboard },
];

export const SIDEBAR_MENU_LIST_NEW = [
  {
    label: "Students",
    link: routes.Users,
  },
  {
    label: "Subjects",
    link: routes.SubjectList,
  },
  {
    label: "Filters",
    link: routes.Filters,
  },
  {
    label: "Questions",
    Icon: RxDashboard,
    subMenu: [
      { label: "All Questions", link: routes.QuestionList, Icon: RxDashboard },
      { label: "Create Question", link: routes.QuestionCreate, Icon: RxDashboard },
    ],
  },
  {
    label: "Instructions",
    Icon: RxDashboard,
    subMenu: [
      { label: "All Instructions", link: routes.InstructionList, Icon: RxDashboard },
      { label: "Create Instruction", link: routes.InstructionCreate, Icon: RxDashboard },
    ],
  },
  {
    label: "Tests",
    Icon: RxDashboard,
    subMenu: [
      { label: "All Tests", link: routes.TestList, Icon: RxDashboard },
      { label: "Create Test", link: routes.TestCreate, Icon: RxDashboard },
    ],
  },
  {
    label: "Published Tests",
    link: routes.PublishedTests,
  },
];

export const DEFAULT_PAGE_SIZE = 20;

export const DRAGGABLE_TYPES = {
  testQuestion: "TEST_QUESTION",
  question: "QUESTION",
  testInstruction: "TEST_INSTRUCTION",
  instructionSpecific: "INSTRUCTION_SPECIFIC",
  instructionGeneral: "INSTRUCTION_GENERAL",
};

export const IMAGE_UPLOAD_TYPES = {
  quesContent: "QUESTION_CONTENT",
  quesChoiceContent: "QUESTION_CHOICE_CONTENT",
  quesSolutionContent: "QUESTION_SOLUTION_CONTENT",
  testCover: `TEST_COVER`,
};

export const FILTER_TARGET_TABLES = {
  tests: "tests",
  questions: "questions",
  users: "users",
};
