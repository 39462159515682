import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, format } from "date-fns";
import uuid from "short-uuid";

export function isAuth() {
  if (localStorage.getItem("accessToken")) return true;
  else return false;
}

export function getAuthToken() {
  return localStorage.getItem("accessToken");
}

export function splitArray(arr, chunkSize) {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}

export function getRemainingTime(targetDate) {
  const now = new Date();

  const days = differenceInDays(targetDate, now);
  const hours = differenceInHours(targetDate, now) % 24;
  const minutes = differenceInMinutes(targetDate, now) % 60;
  const seconds = differenceInSeconds(targetDate, now) % 60;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export function getPageCount(totalCount, pageSize) {
  if (pageSize === 0) return 0;
  return Math.ceil(totalCount / pageSize);
}

export function parseIntObj(obj) {
  const parsed = {};
  for (const key in obj) {
    parsed[key] = parseInt(obj[key]);
  }
  return parsed;
}

export function isEqualObjects(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !isEqualObjects(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function formatDateTime(dateTime) {
  const date = new Date(dateTime);
  return format(date, "dd MMM HH:mm");
}

export function getTimeFromSeconds(seconds) {
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const sec = seconds % 60;

  const paddedDays = String(days).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(sec).padStart(2, "0");

  return {
    days: paddedDays,
    hours: hours,
    minutes: paddedMinutes,
    seconds: paddedSeconds,
  };
}

export function genUUID(len = 4) {
  return uuid.generate().substring(0, len);
}

export function getPartialObj(fullObj, keys) {
  const newObj = {};
  keys.forEach((key) => {
    newObj[key] = fullObj[key];
  });
  return newObj;
}

export function deleteObjKeys(obj, keysArr) {
  keysArr.forEach((key) => {
    delete obj[key];
  });
}

export function isNullObjKeys(obj, keys) {
  let isNull = true;

  keys.forEach((key) => {
    const isNullKey = obj[key] === null || obj[key] === undefined;
    isNull = isNull && isNullKey;
  });

  return isNull;
}

export function updateArray(arr, key, value, newValues) {
  return arr.map((obj) => {
    if (obj[key] === value) {
      return { ...obj, ...newValues };
    }
    return obj;
  });
}

export function isRootNode(node) {
  if (!node) return false;

  const { path } = node;
  if (!path.includes(".")) return true;
  else return false;
}
