import React from "react";
import { Navigate } from "react-router-dom";

import routes from "./routes";
import { isAuth } from "../utils/lib";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  return isAuth() ? Component : <Navigate to={routes.Login} />;
};

export default ProtectedRoute;
