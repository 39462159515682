import apiBaseUrls from "./apiBaseUrls.json";

const { VITE_API_BASE_URL: baseUrl, VITE_API_TENANT_URL: tenantUrl, VITE_TENANT: tenant } = import.meta.env;

function removeWWWFromURL(url) {
  if (url.startsWith("www.")) return url.substring(4);
  return url;
}

function getBaseUrl() {
  const { hostname } = window.location;
  const hostnameWithoutWWW = removeWWWFromURL(hostname);

  if (baseUrl) {
    return baseUrl;
  }

  const { apiBaseUrl } = apiBaseUrls.find((f) => f.hostname.toLowerCase() === hostnameWithoutWWW.toLowerCase());
  return apiBaseUrl;
}

function getTenantUrl(baseUrl) {
  if (tenantUrl) {
    return tenantUrl;
  }

  return `${baseUrl}/${tenant.toLowerCase()}`;
}

const apiBaseUrl = getBaseUrl();
const apiTenantUrl = getTenantUrl(apiBaseUrl);

const config = {
  apiBaseUrl,
  apiTenantUrl,
};

export default config;
