import { create } from "zustand";
const useUserStore = create((set) => ({
  profile: {
    name: "",
    email: "",
  },
  authTokens: {
    access: "",
    refresh: "",
  },

  setUserProfile: (payload) => set((prev) => ({ profile: { ...prev.profile, ...payload } })),
  setAuthTokens: (payload) => set((prev) => ({ tokens: { ...prev.authTokens, ...payload } })),
}));

export default useUserStore;
