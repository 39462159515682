import React, { useState } from "react";
import style from "./Sidebar.module.scss";

import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";

import { SIDEBAR_MENU_LIST_NEW } from "@src/utils/constants";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuClick = (menu) => {
    if (menu.link && menu.link.length) {
      navigate(menu.link);
      setActiveMenu(null);
    } else {
      const firstSubMenu = menu.subMenu[0];
      if (firstSubMenu && firstSubMenu.link) {
        navigate(firstSubMenu.link);
      }
      setActiveMenu((prevMenu) => (prevMenu === menu.label ? null : menu.label));
    }
  };

  const handleClickSubMenu = (subMenu) => {
    navigate(subMenu.link);
  };

  const isActiveSubLink = (item) => location.pathname === item.link;

  const isActiveLink = (item) => {
    if (Array.isArray(item.subMenu)) return false;
    return location.pathname === item.link;
  };

  return (
    <div className={style.wrapper}>
      <ul className={style.menuList}>
        {SIDEBAR_MENU_LIST_NEW.map((menu) => (
          <li key={menu.label} className={clsx(style.menuListItem, isActiveLink(menu) && style.active)}>
            <div onClick={() => handleMenuClick(menu)} className={style.topLevel}>
              <span className={style.menuLabel}>{menu.label}</span>
              {menu.subMenu && menu.subMenu.length > 0 && (
                <span className={style.arrowIcon}>
                  {activeMenu === menu.label ? <FaChevronDown size={12} /> : <FaChevronRight size={12} />}
                </span>
              )}
            </div>

            {menu.subMenu?.length && activeMenu === menu.label && (
              <ul className={style.subMenuList}>
                {menu.subMenu.map((subMenu) => (
                  <li
                    key={subMenu.label}
                    onClick={() => handleClickSubMenu(subMenu)}
                    className={clsx(isActiveSubLink(subMenu) && style.active)}
                  >
                    <div className={style.topLevel}>
                      <span>{subMenu.Icon && <subMenu.Icon />}</span>
                      <span>{subMenu.label}</span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
