import { BrowserRouter, useLocation } from "react-router-dom";
import style from "./App.module.scss";

import Router from "@src/routes/AppRouter";
import routes from "@src/routes/routes";

import Navbar from "@components-layout/Navbar";
import Sidebar from "@components-layout/Sidebar";
import ErrorBoundary from "@components-pages/ErrorBoundary";

import useGlobalEffects from "./useGlobalEffects";
import { ToastContainer } from "react-toastify";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

/* Global styles for installed components */
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ROUTES_WITH_NAV_HIDDEN = [routes.Login];

function App() {
  useGlobalEffects();
  const location = useLocation();
  const { pathname } = location;

  const isHideNav = ROUTES_WITH_NAV_HIDDEN.some((f) => pathname.includes(f));

  return (
    <div className={style.wrapper}>
      {!isHideNav ? (
        <>
          <Navbar />
          <div className={style.bodyContainer}>
            <Sidebar />
            <main className={style.mainContainer}>
              <div className={style.inner}>
                <Router />
              </div>
            </main>
          </div>
        </>
      ) : (
        <>
          <Router />
        </>
      )}
    </div>
  );
}

function WithBrowserRouter() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <ToastContainer
          theme="colored"
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default WithBrowserRouter;
