import axios from "axios";
import config from "../config/config.js";
import { getAuthToken } from "@src/utils/lib";
import { toast } from "react-toastify";

const { apiBaseUrl, tenantBaseUrl } = config;

function createClient(baseURL) {
  const client = axios.create({
    baseURL,
  });

  client.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error.response && error.response.status === 403) {
        toast.error("Session expired. Please log in again", { toastId: "SESSION_EXPIRED" });
        localStorage.clear();
        window.location.href = "/login";
        return;
      }

      if (error.response && error.response.status === 401) {
        localStorage.clear();
        toast.error("Incorrect Credentials", { toastId: "INCORR_CREDS" });
        return;
      }

      error.response && error.response.status != 400 ? toast.error("Network error", { toastId: "API_ERROR" }) : "";
      return Promise.reject(error);
    },
  );

  return client;
}

function getClient(apiServer) {
  switch (apiServer) {
    case "BASE":
      return BaseClient;
    case "TENANT":
      return TenantClient;
    default:
      return BaseClient;
  }
}

/* ------------------------------------------------------------ */

const BaseClient = createClient(apiBaseUrl);
const TenantClient = createClient(tenantBaseUrl);
/* ------------------------------------------------------------ */

export const getRequest = (path, query, opts) => {
  let { responseType, client } = opts || {};
  if (!responseType) responseType = "json";

  return getClient(client).get(`${path}`, {
    params: query,
    responseType: responseType,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getAuthToken()}`,
    },
  });
};

export const postRequest = (path, data, query, opts) => {
  let { client } = opts || {};

  return getClient(client).post(`${path}`, data, {
    params: query,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getAuthToken()}`,
    },
  });
};

export const postRequestMultiPart = (path, data, query, opts) => {
  let { client } = opts || {};

  return getClient(client).post(`${path}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${getAuthToken()}`,
    },
  });
};

export const patchRequest = (path, data, query, opts) => {
  let { client } = opts || {};

  return getClient(client).patch(`${path}`, data, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getAuthToken()}`,
    },
  });
};

export const headRequest = (path, query, opts) => {
  let { client } = opts || {};

  return getClient(client).head(`${path}`, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getAuthToken()}`,
    },
  });
};

export const deleteRequest = (path, body, query, opts) => {
  let { client } = opts || {};

  return getClient(client).delete(`${path}`, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getAuthToken()}`,
    },
    data: body,
  });
};

export const processQuestionImages = async (question) => {
  const respImages = await getRequest(`/questions/${question.uid}/images`);

  const updatedData = { ...question };
  const srcRegex = /<img\s+src="([^"]+)"/g;
  const keyRegex = /\.com\/([^?]+)/;

  const replaceImageUrls = (content) => {
    const srcMatches = [...content.matchAll(srcRegex)];
    srcMatches.forEach((srcMatch) => {
      const srcValue = srcMatch[1];
      const keyMatch = srcValue.match(keyRegex);

      if (keyMatch) {
        const key = keyMatch[1];
        const foundKeyInRespImages = respImages.data.data.find((f) => f.key === key);

        if (foundKeyInRespImages) {
          const { signedUrl } = foundKeyInRespImages;
          content = content.replace(srcValue, signedUrl);
        }
      }
    });
    return content;
  };

  updatedData.content = replaceImageUrls(updatedData.content);
  updatedData.choices = updatedData.choices?.map((choice) => ({
    ...choice,
    content: replaceImageUrls(choice.content),
  }));

  if (updatedData.solution_content) {
    updatedData.solution_content = replaceImageUrls(updatedData.solution_content);
  }

  return updatedData;
};
