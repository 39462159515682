import React from "react";
import style from "./Navbar.module.scss";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { IoMenu, IoClose, IoLogOutOutline } from "react-icons/io5";
import clsx from "clsx";
import { FiBell } from "react-icons/fi";

import logo from "@assets/images/logo.png";
import user from "@assets/images/user.png";

export default function Navbar() {
  function handleLogout() {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  }

  return (
    <Disclosure as="nav" className="tw-bg-white tw-sticky tw-top-0">
      {({ open }) => (
        <section>
          <div className={clsx(style.wrapper, "withPadding")}>
            {/* Mobile Menu Button (visible only on mobile) */}
            <div className="tw-ml-2 tw-mr-2 tw-flex tw-items-center md:tw-hidden">
              <DisclosureButton className="tw-inline-flex tw-items-center tw-justify-center tw-p-2 tw-rounded-md tw-text-gray-400 hover:tw-text-white hover:tw-bg-gray-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-white">
                <span className="tw-sr-only">Open main menu</span>
                {open ? (
                  <IoClose className="tw-block tw-h-6 tw-w-6" aria-hidden="true" />
                ) : (
                  <IoMenu className="tw-block tw-h-6 tw-w-6" aria-hidden="true" />
                )}
              </DisclosureButton>
            </div>

            {/* Logo */}
            <img src={logo} alt="Logo" className={clsx("tw-w-[138px]", style.logoImg)} />

            {/* Navbar items visible on medium and larger devices */}
            <div className="tw-hidden md:tw-flex md:tw-ml-6 md:tw-items-center tw-justify-end md:tw-space-x-4 tw-w-full">
              <div className="tw-flex tw-items-center tw-gap-4">
                {/* <DropDown /> */}
                {/* <h3 className="tw-text-lg tw-text-[#667085]">
                  <FiBell />
                </h3> */}
                <img src={user} alt="User" className="tw-pl-4" />
                <span className="tw-flex tw-items-center tw-cursor-pointer" onClick={handleLogout}>
                  <IoLogOutOutline className="tw-text-xl tw-mr-2" />
                  Logout
                </span>
                {/* <span onClick={handleLogout}>Logout</span> */}
              </div>
            </div>
          </div>

          {/* Disclosure panel for mobile devices */}
          <DisclosurePanel className="md:tw-hidden tw-flex tw-flex-col tw-items-center tw-justify-start tw-p-3 tw-w-full">
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-4">
              {/* <DropDown /> */}
              {/* <h3 className="tw-text-lg tw-text-[#667085]">
                <FiBell />
              </h3> */}
              <img src={user} alt="User" className="" />
            </div>
          </DisclosurePanel>
        </section>
      )}
    </Disclosure>
  );
}
