import { useEffect } from "react";

import useUserStore from "@stores/User.store";

import { getUserProfile } from "@api/user.api";
import { getAuthToken, isAuth } from "@utils/lib";

export default function useGlobalEffects() {
  const { setUserProfile, setAuthTokens } = useUserStore();

  async function initUser() {
    const { data } = await getUserProfile();
    const accessToken = getAuthToken();
    setUserProfile(data);
    setAuthTokens({ access: accessToken });
  }

  useEffect(() => {
    if (!isAuth()) return;

    initUser();
  }, []);
}
