import { getRequest } from "./_setup";

export async function getUserProfile() {
  const { data } = await getRequest(`/users`);
  return data;
}

export async function getUserProfileById(userId) {
  const { data } = await getRequest(`/users/${userId}`);
  return data;
}

export async function getAllUsers() {
  const { data } = await getRequest(`/users/all`);
  return data;
}

export async function getUserTestPerformance(userId) {
  const { data } = await getRequest(`/users/tests/performance?user_id=${userId}`);
  return data;
}

export async function getUserTestPerformanceSummary(userId) {
  const { data } = await getRequest(`/users/tests/performance/summary?user_id=${userId}`);
  return data;
}
