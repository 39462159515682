const routes = {
  Home: "/",
  Login: "/login",
  TestCreate: "/tests-new",
  TestList: "/tests",
  QuestionCreate: "/questions-new",
  QuestionList: "/questions",
  InstructionList: "/instructions",
  InstructionCreate: "/instructions-new",
  PublishedTests: "/published-tests",
  TestPublications: "/published-tests/publications",
  TestAttempts: "/published-tests/publications/attempts",
  AttemptResponses: "/published-tests/publications/attempts/responses",
  Users: "/users",
  Filters: "/filters",
  SubjectList: "/subjects",
};

export default routes;
