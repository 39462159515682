import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./index/App.jsx";
import "./index.scss";

window.global ||= window;
/* https://stackoverflow.com/questions/72114775/vite-global-is-not-defined */

createRoot(document.getElementById("root")).render(
  // <StrictMode>
  <App />,
  // </StrictMode>,
);
